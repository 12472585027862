import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import LandingRisks from '@/modules/landing/components/risks/LandingRisks.vue';
import LandingFooter from '@/modules/landing/components/footer/LandingFooter.vue';
import LandingSteps from '@/modules/landing/components/steps/LandingSteps.vue';
import LandingBenefits from '@/modules/landing/components/benefits/LandingBenefits.vue';
import { titleTemplate } from '@/helpers/meta/title-template';

@Component({
  components: {
    BannerLegal,
    Navbar,
    LandingRisks,
    LandingFooter,
    LandingSteps,
    LandingBenefits,
  },
})

export default class HowItWorks extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.how-it-works.title').toString()),
    };
  }
}
