import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LineCircle from '@/assets/images/landing/steps-pointer.svg';
import CTA from '@/components/common/cta/Cta.vue';

@Component({
  components: {
    LineCircle,
    CTA,
  },
})

export default class LandingSteps extends Vue {
  @Prop() inverted!: boolean;
}
