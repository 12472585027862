import { render, staticRenderFns } from "./Cta.html?vue&type=template&id=3806ad42&scoped=true&"
import script from "./Cta.ts?vue&type=script&lang=ts&"
export * from "./Cta.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/cta/_cta.scss?vue&type=style&index=0&id=3806ad42&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3806ad42",
  null
  
)

export default component.exports