import { render, staticRenderFns } from "./HowItWorks.html?vue&type=template&id=f8a2acfe&scoped=true&"
import script from "./HowItWorks.ts?vue&type=script&lang=ts&"
export * from "./HowItWorks.ts?vue&type=script&lang=ts&"
import style0 from "@/scss/components/how-it-works/_how-it-works.scss?vue&type=style&index=0&id=f8a2acfe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a2acfe",
  null
  
)

export default component.exports