import { Vue, Component } from 'vue-property-decorator';
import { MetaInfo } from 'vue-meta';
import Support from '@/components/common/support/Support.vue';
import BannerLegal from '@/components/common/banner-legal/BannerLegal.vue';
import Navbar from '@/components/common/navbar/Navbar.vue';
import CTA from '@/components/common/cta/Cta.vue';
import LandingRisks from '@/modules/landing/components/risks/LandingRisks.vue';
import LandingFooter from '@/modules/landing/components/footer/LandingFooter.vue';
import { titleTemplate } from '@/helpers/meta/title-template';
import { intercom } from '../../../whitelabel.config';

@Component({
  components: {
    Support,
    BannerLegal,
    Navbar,
    LandingRisks,
    LandingFooter,
    CTA,
  },
})

export default class AboutUs extends Vue {
  metaInfo(): MetaInfo {
    return {
      title: titleTemplate(this.$t('meta.aboutus.title').toString()),
    };
  }

  intercom = intercom;
}
